.about_us_container {
  margin-top: 100px;
  margin-bottom: 146px;
}
.about_us_row {
  margin-bottom: 20px;
}
.about_us_row h5 {
  color: #0c192c;
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 16px;
}
.about_us_row p {
  color: #878a99;
  font-size: 16px;
  font-weight: 400;
}
.team_container h4 {
  color: #0c192c;
  text-align: center;
  font-size: 29.649px;
  font-weight: 600;
  margin-bottom: 54px;
}
.team_memeber {
  text-align: center;
  margin-bottom: 10px;
}
.team_memeber img {
  margin-bottom: 15px;
}

.team_memeber p {
  color: #0c192c;
  font-family: "Inter", sans-serif;
  font-size: 18.816px;
  font-weight: 600;
  margin-bottom: 8px;
}
.team_memeber span {
  color: #878a99;
  font-family: "Inter", sans-serif;
  font-size: 14.825px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .about_us_container {
    margin-top: 0px;
  }
  .content h5 {
    display: none;
  }
}

.zoom-on-hover {
  transition: transform 0.3s ease; /* Smooth transition */
}

.zoom-on-hover:hover {
  transform: scale(1.1); /* Zoom in by 10% */
}

.term_heading {
  font-size: 18px;
  font-weight: 500;
}

.term_paragraph {
  font-size: 14px;
  font-weight: 400;
}