@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: Rage;
  src: url(./fonts/RAGE_1.TTF);
}

@font-face {
  font-family: "1942";
  src: url(./fonts/1942.ttf);
}
@font-face {
  font-family: BrokenGlass;
  src: url(./fonts/BrokenGlass.otf);
}
@font-face {
  font-family: BrokenGlass_2;
  src: url(./fonts/BrokenGlass.ttf);
}
@font-face {
  font-family: ChaosTimes;
  src: url(./fonts/ChaosTimes_lig.TTF);
}
@font-face {
  font-family: Ghastly;
  src: url(./fonts/Ghastly\ Panic.ttf);
}
@font-face {
  font-family: GrutchShaded;
  src: url(./fonts/GrutchShaded.ttf);
}
@font-face {
  font-family: Halo;
  src: url(./fonts/Halo.ttf);
}
@font-face {
  font-family: Halo_Outline;
  src: url(./fonts/Halo\ Outline.ttf);
}
@font-face {
  font-family: JUNGLEFE;
  src: url(./fonts/JUNGLEFE.TTF);
}
@font-face {
  font-family: OldNewspaperTypes;
  src: url(./fonts/OldNewspaperTypes.ttf);
}
@font-face {
  font-family: SuperOrDessert;
  src: url(./fonts/Super\ Dessert.ttf);
}
@font-face {
  font-family: SuperMario256;
  src: url(./fonts/SuperMario256.ttf);
}
@font-face {
  font-family: Tomb_Raider;
  src: url(./fonts/Tomb_Raider.ttf);
}
@font-face {
  font-family: varsity_regular;
  src: url(./fonts/varsity_regular.ttf);
}

* {
  /* border: 1px solid blue; */
  color: #000;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}
a {
  color: #000;
  text-decoration: none;
}
a:hover {
  color: inherit;
}
h2 {
  color: #111827;
  text-align: center;
  font-size: 45px;
  font-weight: 600;
}

/* Top Heading Row */

.top_heading {
  background: #fcfb00;
}
.top_heading .container {
  padding: 31px 0px;
}
.top_heading h3 {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}
.top_heading span {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.top_heading_name {
  padding-top: 20px;
  display: none;
  /* margin-bottom: 20px; */
}

/* Top Heading Row */

/* Segmented btns and Dropdowns */

.segmented-buttons {
  display: flex;
  overflow: hidden;
  border-radius: 9999px;
  background: #fbfbfb;
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 47px;
  margin-bottom: 36px;
  flex-wrap: wrap;
}

.segmented-button {
  margin: 4px 4px;
  flex: 1;
  padding: 15px 10px;
  border: none;
  cursor: pointer;
  border-radius: 9999px;
  background: #fbfbfb;
  color: #475569;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  /* transition: .3s; */
}

.segmented-button.active {
  background: #d4d4d4;
  color: #000000;
}

.select_box {
  padding: 0px 8px;
}
.item_selection {
  margin-bottom: 128px;
}
.item_selection .select_con {
  position: relative;
  width: max-content;
}

.item_selection .select_con img,
.dropdown_row button img {
  position: absolute;
  top: 12px;
  left: 16px;
}

.item_selection select {
  border-radius: 9999px;
  border: 1px solid #d1d5db;
  padding: 9px 40px 9px 40px;
  color: #374151;
  font-size: 14px;
  font-weight: 400;
}
.form-control:focus {
  background-color: #fff;
  border-color: #d1d5db;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0%) !important;
}
.form-select:focus {
  border-color: #86b7fe;
  border: 1px solid #d1d5db;
  box-shadow: none;
}
.item_selection select option {
  color: #374151;
  font-size: 14px;
  font-weight: 400;
}
.dropdown_row {
  flex-wrap: nowrap;
  margin-bottom: 14px;
  overflow-x: auto;
  padding-bottom: 10px;
}
.dropdown_row button {
  position: relative;
  border-radius: 9999px;
  border: 1px solid #d1d5db;
  background: transparent;
  padding: 9px 23px 9px 40px;
  color: #374151;
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}

.card_products {
  margin-bottom: 67px;
}
.card {
  border-radius: 24px;
  background: #f8fafc;
  margin-bottom: 22px;
  border: none;
}
.product_img {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card .title {
  color: #111827;
  font-size: 16px;
  font-weight: 600;
}
.card .price {
  color: #b4b4b4;
  font-size: 14px;
  font-weight: 500;
}
.p_details {
  margin-bottom: 15px;
}
.card button {
  border-radius: 5667.636px;
  background: #fcfb00;
  box-shadow: 0px 2.2672810554504395px 3.400921583175659px -2.2672810554504395px
      rgba(0, 0, 0, 0.1),
    0px 5.6682024002075195px 8.502304077148438px -1.7004607915878296px rgba(0, 0, 0, 0.1);
  border: none;
  outline: none;
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding: 9px;
  margin-bottom: 8px;
}
.card_products {
  display: flex;
  flex-wrap: wrap;
}

.card_products .col-sm-6 {
  display: flex;
}

.card_products .card {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card_products .card_below_content {
  margin-top: auto;
}

/* Segmented btns and Dropdowns */

/* Scroll Bar */

::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #FCFB00;
}
::-webkit-scrollbar-thumb:hover {
  background: #FCFB00;
}

/* Scroll Bar */

@media (max-width: 430px) {
  .segmented-button img {
    display: block;
    margin: auto;
    margin-bottom: 3px;
  }
}
@media (max-width: 576px) {
  .segmented-button {
    font-size: 10px;
  }
  h2 {
    font-size: 30px;
  }

  .segmented-button {
    padding: 6px 0px;
  }
  .item_selection .select_con img,
  .dropdown_row button img {
    width: 15px;
    top: 10px;
    left: 10px;
  }
  .item_selection select {
    font-size: 10px;
    padding: 9px 30px;
  }
  .dropdown_row button {
    padding: 9px 19px 9px 30px;
    font-size: 10px;
  }
}
@media (max-width: 420px) {
  .segmented-buttons {
    border-radius: 0;
  }
  .segmented-button{
    padding: 10px;
  }
}
@media (max-width: 767px) {
  /* .segmented-buttons {
    width: 100%;
  } */
  .segmented-button {
    font-size: 12px;
  }
  .segmented-button img {
    width: 12px;
  }
  .top_heading {
    display: none;
  }
  .top_heading_name {
    display: block;
  }
}

.main {
  height: 100vh;
}
.navbar{
  background: #fff;
}
.navbar,.navbar-home {
  padding: 10px 0px;
  box-shadow: 0px 2px 10px 0px rgba(218, 195, 195, 0.1);
}
button:hover {
  cursor: pointer !important;
}
.navbar-brand {
  margin: auto;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar .searchbar,.navbar-home .searchbar {
  width: 580px;
  position: relative;
}
.navbar .searchbar i,.navbar-home .searchbar i {
  position: absolute;
  top: 4px;
  left: 16px;
  font-size: 25px;
  color: #b4b4b4;
}

.navbar .searchbar input,.navbar-home .searchbar input {
  border-radius: 5px;
  /* border: 1px solid #B4B4B4 !important; */
  outline: none;
  padding: 9px 52px;
  width: 100%;
}
.navbar .searchbar input::placeholder,.navbar-home .searchbar input::placeholder {
  color: #b4b4b4;
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
}

.navbar .search_imgs,.navbar-home .search_imgs {
  margin-left: 25px;
  cursor: pointer;
}

.login-form{
  margin-left: 0.5rem;
  background-color: #FCFB00;
  border: none;
  padding: 9px 16px;
  border-radius: 20px;
  font-size: 16px;
  text-align: center;
}

.bottom_nav {
  background-color: #f6f6f6;
}
.bottom_nav .row,.bottom_nav-home .row {
  padding-top: 21px;
  padding-bottom: 21px;
}
.bottom_nav span,.bottom_nav-home .row {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}
.list_dropdown {
  padding-left: 0px;
  padding-right: 10px;
}
.list_dropdown .dropdown-center button {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}
.list_dropdown .dropdown-menu {
  background: #fbfbfb;
  box-shadow: 2px 2px 10px 0px rgba(212, 212, 212, 0.5);
}
.list_dropdown .dropdown-menu li a {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
}
.nav_div {
  display: none;
}

/* Drawer */
.drawer {
  display: flex;
}
.drawer_con {
  display: inline-flex !important;
}
.drawer .drawer_cart_img {
  margin-right: 5px;
  display: none;
  cursor: pointer;
}

.offcanvas-header {
  justify-content: end;
  padding: 22px 24px;
}
.offcanvas-body {
  padding: 0px 24px 24px 24px;
}
.offcanvas-body span {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.offcanvas-body span a {
  text-decoration: none;
  color: inherit;
}
.drawer .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.drawer .accordion-button:not(.collapsed) {
  color: #000;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  background-color: none;
  box-shadow: none;
}
.drawer .accordion-item:first-of-type {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.drawer .accordion-item {
  background-color: #fff;
  border: none !important;
}
.drawer .accordion-button {
  background-color: transparent !important;
}
.drawer .accordion-button:focus {
  z-index: 3;
  border-color: transparent !important;
  outline: 0;
  box-shadow: none !important;
}

.drawer .accordion-button::after {
  margin-left: 10px !important;
}
.drawer .accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
}

.drawer .accordion-button {
  font-size: 18px;
  padding: 10px 0px;
  font-weight: 500;
}
.drawer .accordion-body {
  padding: 0px 15px;
}
.drawer .accordion-body li {
  list-style-type: none;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

/* Drawer */

/* Footer */

.footer {
  background-image: url(./assets/images/footer_background.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 175px;
  padding-bottom: 64px;
}
.footer .logo_img {
  margin-bottom: 10px;
}
.footer h5 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.footer p {
  color: #d4d4d4;
  font-size: 14px;
  font-weight: 400;
}
.footer p a {
  text-decoration: none;
  color: #d4d4d4;
}

.footer .last_text {
  color: #d4d4d4;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
}
.social_media_links span {
  margin-right: 7px !important;
}
.social_media_links span img {
  height: 24px;
  width: 24px;
}

/* Footer */

@media (max-width: 350px) {
  /* .drawer {
    display: block;
    margin: auto;
  }
  .nav_con {
    display: block !important;
    text-align: center;
  } */
  .nav_div {
    height: 90px !important;
  }
}
@media (max-width: 430px) {
}

@media (max-width: 500px) {
}
@media (max-width: 576px) {
  h2 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
}
@media (max-width: 991px) {
  .navbar,.navbar-home {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .nav_div {
    display: block;
    height: 80px;
  }

  .drawer .drawer_cart_img {
    display: block;
  }

  .drawer_toggler_btn {
    display: block !important;
    padding: 0px;
  }
  .navbar {
    background: #fff !important;
  }
  .bottom_nav,.bottom_nav-home {
    display: none !important;
  }
  .footer {
    padding-top: 40px;
    padding-bottom: 10px;
  }
  .bottom_logo {
    margin-bottom: 35px;
  }
  .footer h5 {
    margin-bottom: 15px;
  }
  .footer p {
    margin-bottom: 8px;
  }
  .faq_section {
    margin-bottom: 80px;
  }
}
