.parentToggle{
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
}

.parentToggle > h1 {
    font-size: 18px;
    padding-top: 5px;
    font-weight: 600;
}

.toggle-switch {
    width: 55px;
    height: 30px;
    background-color: #ccc;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .toggle-switch.on {
    background-color: #4caf50;
  }
  
  .toggle-knob {
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: 3.3px;
    transition: left 0.3s;
  }
  
  .toggle-switch.on .toggle-knob {
    left: 26px;
  }
  