.top_heading{
    margin-bottom: 29px;
}
.limit_row{
    margin-bottom: 24px;
}
.limit_row {
    color: #0C192C;
    font-size: 15.5px;
    font-weight: 500;
}
.limit_row span{
    font-size: 15.5px;
    font-weight: 800;
}
.limit_row a{
    color: #7C6BFF;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
    text-decoration-line: underline;
}

.cart_card{
    border-radius: 4.8px;
    border: 1px solid #E9EBEC;
    background: #FFF;
    padding: 20px;
}

.row1{
    font-size: 15px;
    font-weight: 500;
}
.row1 .edit_del_icons svg{
    margin: 0px 3px;
}
.row2 .p_attribute{
    color: #878A99;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
}
.row2 .pa2{
    margin-left: 5px;
}

.row2 .p_value{
    color: #878A99;
    font-size: 12px;
    font-weight: 500;
}
.row2 .increase_decrease{
    border-radius: 4px;
    border: 1px solid #E9EBEC;
    background: #FFF;
    padding: 5px;
    margin-top: 10px;
}
.row2 .increase_decrease button{
    border-radius: 4px;
    background: #F3F6F9;
    outline: none;
    border: none;
   
}

.row2 .increase_decrease input{
    border: none;
    width: 35px;
    text-align: center;
    pointer-events: none !important;
}
.row3 .price_row{
    color: #878A99;
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
}
.row3 .price_row span{
    color: #0C192C;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
}
.p_price{
    color: #0C192C;
    font-size: 16px;
    font-weight: 600;
}

.promo_code {
    display: flex;
    align-items: center; /* Vertically center the input and button */
    justify-content: space-between; /* Separate the input and button */
    max-width: 400px; /* You can adjust this value to limit the maximum width of the container */
    margin: 0 auto; /* Center the container horizontally */

}

/* Style for the input field */
.promo_code input[type="text"] {
    flex: 1; /* The input field will take up the available space */
    min-width: 0; /* To ensure the input can shrink */
    width: 100%; /* Make sure the input takes full width initially */
}

/* Optional style for the button */
.promo_code button {
    flex-shrink: 0; /* Prevent the button from shrinking */
    /* Add your button styles here */
}
.right_cards{
    margin-bottom: 25px;
}
.cart_card h5{
    color: #0C192C;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}
.cart_card .promo_code input{
    border-radius: 4px;
    border: 1px solid #E9EBEC;
    background: #FFF;
    padding: 8px;
    color: #0C192C;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    margin-right: 16px;

}
.cart_card .promo_code button{
    border-radius: 4px;
    border: 1px solid #B4B4B4;
    background: #B4B4B4;
    padding: 9px 21px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 500;
}

.order_summary{
    margin-bottom: 46px;
}
.summary{
    color: #0C192C;
    font-size: 13px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
}
.check_out{
    margin-top: 10px;
}
.check_out button{
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border-radius: 9999px;
    background: #FCFB00;
    box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
    border: none;
    outline: none;
    padding: 17px 68px;

    
}
.add_more_products button{
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border-radius: 9999px;
    background: #FCFB00;
    box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
    border: none;
    outline: none;
    padding: 17px 68px;

    
}
.check_out button:disabled{
    background-color: #bdbc00;
}

#clear_cart_btn{
    border: none;
    background: none;
    color: blue;
    text-decoration: underline;
}









@media (max-width: 320px) {
    .promo_code {
        flex-direction: column;
    }
    .cart_card .promo_code input {
        margin-right: 0px;
        margin-bottom: 10px;
    }

}

@media (max-width: 580px){
    .row1{
        font-size: 14px;
        font-weight: 500;;
    }
}
@media (max-width: 567px){
    .limit_row {
        font-size: 12px;
    }
    .limit_row span{
        font-size: 13px;
    }
    .limit_row a{
        font-size: 11px;
    }
    .cart_card {
        padding: 10px;
    }
    .row1 {
        font-size: 11px;
    }
    .row1 .edit_del_icons svg{
        width: 14px !important;
    }
    .row2 .increase_decrease{
        padding: 3.5px;
    }
    .row2 .increase_decrease button{
        font-size: 12px;
    }
    .row2 .increase_decrease input{
        font-size: 12px;
    }
    .row3 .price_row{
        font-size: 14px;
    }
    .row3 .price_row span{
        font-size: 14px;
    }
}

@media (min-width: 992px){
    .right-side {
        width: 63.333333% !important;
    }
}
