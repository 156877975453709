/* *{
    border: 1px solid blue;
} */
.varient span {
  font-weight: 600;
}
.super_main {
  margin-bottom: 200px;
}
.p_detail_bar {
  margin-bottom: 77px;
}
.p_detail_bar a {
  color: #1565c0;
  font-size: 14px;
  font-weight: 400;
  margin-right: 23px;
}
.p_detail_bar span {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  font-weight: 400;
}

.product_detail h3 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 30px;
  font-weight: 600;
}
.product_detail .price {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Sarabun", sans-serif;
  font-size: 30px;
  font-weight: 400;
}
.product_detail h4 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 11px;
}

.product_detail .rating {
  margin-bottom: 15px;
}
.product_detail .rating span {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.product_detail .sizes button {
  color: #374151;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  border-radius: 9999px;
  border: 1px solid #d1d5db;
  padding: 9px 44px;
  margin-right: 4px;
  margin-bottom: 8px;
}
.product_detail .sizes {
  margin-bottom: 17px;
}
.product_detail .sizes span {
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  text-transform: capitalize;
  width: 100%;
}
.product_detail .desc {
  margin-bottom: 20px;
}
.product_detail .desc p {
  color: #616161;
  font-size: 14px;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 0px;
}
.product_detail button img {
  position: absolute;
  top: 10px;
  right: 12px;
  z-index: 999 !important;
  display: none;
}
.generate_btn button {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 9999px;
  background: #fcfb00;
  padding: 18px 43px;
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.generate_btn button:disabled {
  background: #a6a500 !important;
}

.product_detail .sizes button.active {
  border: 0.52px solid #fcfb00;
  background: rgba(252, 251, 0, 0.05);
  position: relative;
}
.product_detail .sizes button.active img {
  display: block;
}
.s_btns {
  display: flex;
  align-items: baseline;
}

.s_text {
  min-width: fit-content;
}

.p_colors {
  cursor: default;
  margin-bottom: 10px;
}

.p_colors .color_border {
  width: fit-content;
  margin: 0px;
  display: inline-flex;
  border-radius: 50%;
}
.color_border.active {
  border: 1px solid transparent;
}
.p_colors .color {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  color: transparent;
}

@media (max-width: 767px) {
  .p_detail_bar {
    margin-bottom: 5px;
  }
  .product_detail h3 {
    font-size: 17px;
    font-weight: 600;
  }
  .product_detail .sizes button {
    padding: 7px 25px;
    font-size: 14.275px;
    font-weight: 400;
  }
  .product_detail .price {
    font-size: 18px;
    font-weight: 400;
  }
  .generate_btn {
    text-align: center !important;
  }

  .s_btns {
    justify-content: space-between !important;
    align-items: baseline;
  }
  .size_btns span {
    width: max-content;
    text-align: end;
  }
  .product_detail .desc {
    margin-bottom: 30px;
  }
  .product_detail button img {
    top: 9px;
    right: 6px;
  }
  .product_detail .sizes button {
    padding: 7px 32px;
  }
}
@media (max-width: 576px) {
  .s_text {
    display: none;
  }
}
