.limited_time_designs .card_products{
    margin-top: 80px;
}
.limited_time_designs .card{
    padding: 0px !important;
}

.card_content{
    width: 90%;
    text-align: center;
    margin: auto;
}
.card_content p{
    color: #555; 
    font-size: 12.115px !important;
    font-weight: 400 !important;
    margin: 22px 0px;
}