/* .bottom_nav{
    display: none !important;
} */
.content_con{
    margin: 100px 0px;
}
.order_confirm .row .content_con{
    border-radius: 4.8px;
    border: 1px solid #E9EBEC;
    background: #FFF;
    text-align: center;
}
.order_confirm .content_con img{
    margin: 48px 0px;
}
.order_confirm .content_con h1{
    color: #0C192C;
    font-size: 19px;
    font-weight: 600;
    text-transform: capitalize;
}
.order_confirm .content_con p{
    font-size: 13px;
    color: #878A99;
    font-weight: 400;
    margin-bottom: 8px;
}
.order_confirm .content_con button{
    color: #000;
    font-size: 16px;
    font-weight: 500;
    border-radius: 9999px;
    background: #FCFB00;
    box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
    padding: 17px 64px;
    border: none;
    outline: none;
    margin-top: 26px;
    margin-bottom: 42px;
}

@media (max-width: 767px){
    .order_confirm .row .content_con{
        margin-top: 75px !important;
    }
}