.contact_container {
  margin-top: 155px;
  margin-bottom: 155px;
}
.main_box {
  margin-bottom: 24px;
}
  .box-contact-us {
    border-radius: 4.8px;
    border: 1px solid #e9ebec;
    background: #fff;
    padding: 25px;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
.box-contact-us .contact_content {
  margin-left: 16px;
}
.contact_content h5 {
  color: #0c192c;
  font-size: 16px;
  font-weight: 600;
}
.contact_content p {
  color: #878a99;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
  line-break: anywhere;
}

.right_side {
  margin-top: 20px;
}
.right_side h5 {
  color: #0c192c;
  text-align: center;
  font-size: 22.5px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 24px;
}
.right_side .row {
  margin-bottom: 17px;
}
.right_side .row .col span {
  color: #878a99;
  font-size: 13px;
  font-weight: 500;
}
.right_side .row .col input {
  border-radius: 4px;
  border: 1px solid #e9ebec;
  background: #fff;
  padding: 10px;
  color: #0c192c;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  width: 100%;
  outline: none;
}
.right_side .row .col input::placeholder,
.right_side .row .col textarea::placeholder {
  color: #0c192c;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.right_side .row .col textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e9ebec;
  background: #fff;
  padding: 10px;
  margin-top: 10px;
  outline: none;
}

.send_message {
  margin-top: 21px;
}
.send_message button {
  border: none;
  outline: none;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border-radius: 9999px;
  background: #fcfb00;
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: 20px 50px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact_container {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .contact_container {
    margin-top: 0pc !important;
  }
  .flex_row {
    flex-direction: column-reverse;
  }
  .right_side h5 {
    display: none;
  }

  .send_message button {
    margin-bottom: 20px;
  }
  .main_box .box-contact-us {
    padding: 15px;
  }
  .main_box .box-contact-us img {
    width: 35px;
  }
  .contact_content h5 {
    font-size: 12px;
  }
  .contact_content p {
    font-size: 10px;
  }
}
