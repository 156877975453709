.radio-button-container {
    display: flex;
    flex-direction: column;
  }
  
  .radio-label {
    margin-bottom: 8px;
    font-size: 12px;
  }
  
  input[type='radio'] {
    margin-right: 8px;
  }
  