.term_heading {
  font-size: 18px;
  font-weight: 500;
}

.term_paragraph {
  font-size: 14px;
  font-weight: 400;
}

.email_text{
    color: #000;
    font-weight: 600;
}

.zoom-on-hover {
  transition: transform 0.3s ease; /* Smooth transition */
}

.zoom-on-hover:hover {
  transform: scale(1.1); /* Zoom in by 10% */
}
