.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.dropdown-submenu .dropdown-menu-mobile-bottom {
  left: 0 !important;
  top: 125% !important;
  margin-top: -1px;
}
