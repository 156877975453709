.filepond--file-action-button {
  background-color: #ffd843 !important;
}

.filepond--credits {
  display: none;
}

.filepond--wrapper {
  margin: 20px;
}

.filepond--drop-label {
  padding: 100px;
}

.doka--root .doka--range-input input[type="range"]::-webkit-slider-thumb {
  background-color: #ccc; /* Optional: change the color of the thumb */
}

.filepond--file-action-button {
  background-color: white;
}

.doka--root .doka--filter-tile.doka--filter-tile input:checked ~ label {
  color: #ffd843 !important;
}

.doka--button > span {
  color: #000 !important;
}

.doka--filter-list > li > label {
  color: #000 !important;
}

.tabs-design {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
}

.activeTab {
  color: #fcfb00;
  font-size: 14px;
}

.notActiveTab {
  color: #d4d4d4;
  font-size: 14px;
}

.sidebar {
  margin: 0;
  padding: 0;
  overflow: auto;
  background: #000;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.sidebar_con {
  margin: 18px 0px;
}
.sidebar li {
  display: block;
  color: black;
  text-decoration: none;
  color: #d4d4d4;
  cursor: pointer;
  text-align: center;
  font-size: 11.475px;
  font-weight: 400;
  margin: 12px 0px;
}

.tui-image-editor-controls-logo img {
  display: none;
}
.sidebar li svg {
  margin-bottom: 10px;
}

.sidebar a.active {
  background-color: #04aa6d;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

.all_content {
  margin-top: 50px;
  padding: 1px 16px;
}

.sidebar_con li.active,
li.active svg path {
  color: #fcfb00;
  fill: #fcfb00;
}
.sidebar_con .nav-pills {
  justify-content: space-evenly;
}

.canvas-holder {
  align-items: flex-start !important;
}

@media screen and (max-width: 991px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    overflow-y: hidden;
  }
  .sidebar_con {
    margin-top: 0px;
  }
  .sidebar_con .nav-pills {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap !important;
    margin-top: 10px;
  }
  .sidebar li {
    float: left;
    margin-bottom: 0px;
  }
  .all_content {
    margin-left: 0;
  }
  .content .row {
    margin-top: 20px;
  }
}

/* SIDE BAR */

.generate .modal-dialog {
  max-width: 90%;
}

@media (max-width: 991px) {
  .generate .modal-dialog {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .all_content {
    margin-top: 20px;
  }
}

.right_side_design {
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.right_side_design .p_heading {
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-size: 22.122px;
  font-weight: 600;
  margin: 25px 0px;
}

.product_detail h3 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 600;
}
.product_detail .price {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Sarabun", sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;
}
.product_detail h4 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 11px;
}

.product_detail .rating {
  margin-bottom: 10px;
}
.product_detail .rating span {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.product_detail .sizes button {
  color: #374151;
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  outline: none;
  border-radius: 9999px;
  border: 1px solid #d1d5db;
  padding: 7px 40px;
  margin-right: 4px;
  margin-bottom: 8px;
}
.product_detail .sizes {
  margin-bottom: 17px;
}
.product_detail .sizes span {
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  text-transform: capitalize;
  width: 100%;
}
.product_detail .desc {
  margin-bottom: 20px;
}
.product_detail .desc p {
  color: #616161;
  font-size: 12px;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 0px;
}
.product_detail button img {
  position: absolute;
  top: 7px;
  right: 8px;
  z-index: 999 !important;
  display: none;
}
.generate_btn input {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 9999px;
  background: #fcfb00;
  padding: 18px 43px;
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.save_btn button {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 9999px;
  background: #fcfb00;
  padding: 15px 63px;
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
  margin-bottom: 23px;
}

.product_detail .sizes button.active img {
  display: block;
}
.product_detail .sizes button.active {
  border: 0.52px solid #fcfb00;
  background: rgba(252, 251, 0, 0.05);
  position: relative;
}

.s_text {
  min-width: fit-content;
}
.choose_img {
  display: grid;
  gap: 10px; /* Specify the gap between grid items */

  /* For small screens (up to 767px), we'll have 2 columns */
  grid-template-columns: repeat(2, 1fr);

  /* For larger screens, we'll adjust the columns using media queries */
}

@media (min-width: 768px) and (max-width: 991px) {
  .choose_img {
    grid-template-columns: repeat(2, 1fr); /* Medium screens: 2 columns */
    gap: 20px;
  }
}

@media (min-width: 992px) {
  .choose_img {
    grid-template-columns: repeat(4, 1fr); /* Large screens and up: 4 columns */
    gap: 20px;
  }
}
.choose_img img {
  cursor: pointer;
}
.choose_img img:hover {
  scale: 1.05;
  transition: 0.3s all;
}

/* Add Text */

.add_text span {
  color: #878a99;
  font-size: 14.963px;
  font-weight: 500;
  width: max-content;
  display: inline-block;
}
.add_text input {
  border-radius: 5.755px;
  border: 1.151px solid #e9ebec;
  background: #fff;
  outline: none;
  width: 100%;
  padding: 7px;
  margin-top: 8px;
}
.add_text select {
  border-radius: 5.755px;
  border: 1.151px solid #e9ebec;
  outline: none;
  margin-top: 8px;
}
.add_text .editing_btns {
  width: max-content;
  margin-top: 8px;
}
.add_text .edit_btn {
  border-radius: 5.755px;
  border: 1.151px solid #e9ebec;
  color: #878a99;
  font-size: 14.963px;
  font-weight: 400;
  padding: 6px 8px;
}
.add_text .edit_filds {
  margin-bottom: 8px;
}

/* Add Text */

/* Upload Images */

.browse_images {
  margin-top: 100px;
}
.browse_images .cloud_svg {
  text-align: center;
  margin-bottom: 18px;
}
.browse_images h5 {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 18px;
}
.browse_images button {
  border-radius: 11617.007px;
  border: 1.162px solid #b4b4b4;
  box-shadow: 0px 4.6472673416137695px 6.970900535583496px -4.6472673416137695px
      rgba(0, 0, 0, 0.1),
    0px 11.618168830871582px 17.42725372314453px -3.485450267791748px rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 18.589px;
  font-weight: 500;
  padding: 20px 90px;
}

.upload_card {
  border-radius: 5.15px;
  background: #fff;
  box-shadow: 0px 1.0300977230072021px 2.0601954460144043px 0px
    rgba(196, 196, 196, 0.5);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  max-width: 95%;
}
.upload_card .progress {
  border-radius: 10.301px;
  height: 7px;
  margin: 5px 0px;
  background: #c4c4c4;
}
.upload_card .progress-bar {
  background-color: #06c00d;
}
.progress_cancled {
  border-radius: 15.361px;
}
.progress_cancled .progress-bar {
  background: #ff8906;
}
.canvas_part {
  margin-bottom: 30px;
}

/* Upload Images */

.color_border {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.editor_img_container {
  padding: 6px;
  border: 1px solid #ddd;
}

.p_detail {
  background-color: #f8f8f8;
  padding: 10px 15px;
  border-radius: 8px;
  text-align: center;
}
.p_img_con {
  margin-bottom: 23px;
}

.revert_bg {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 9999px;
  background: #fcfb00;
  padding: 18px 43px;
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-top: 25px;
}
.generate_preview {
  margin-top: 15px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 9999px;
  background: #fcfb00;
  padding: 18px 43px;
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

#cart_btn:disabled {
  background: #acac009c;
  cursor: not-allowed;
}

@media screen and (max-width: 991px) {
  .li_close_btn {
    display: block !important;
  }
  .right_side_design .p_heading {
    margin-top: 24px !important;
  }
  .cloud_svg {
    display: none;
  }
  .browse_images {
    margin-top: 0px;
  }
  .browse_images h5 {
    display: none;
  }
  .upload_card {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .product_detail h3 {
    font-size: 17px;
    font-weight: 600;
  }
  .product_detail .sizes button {
    padding: 7px 25px;
    font-size: 14.275px;
    font-weight: 400;
  }
  .product_detail .price {
    font-size: 18px;
    font-weight: 400;
  }
  /* .s_btns{
        justify-content: space-between !important;
        align-items: baseline;
    }
    .size_btns span{
        width: max-content;
        text-align: end;
    } */
  .product_detail .desc {
    margin-bottom: 30px;
  }
  .product_detail button img {
    top: 9px;
    right: 6px;
  }
  .product_detail .sizes button {
    padding: 7px 32px;
  }
  .browse_images button {
    padding: 15px 55px;
  }
  .p_img_con {
    border: 1px solid #ddd;
  }
  .for_flex_direction {
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media (max-width: 575px) {
  .s_text {
    display: none;
  }
}

.e-image-editor {
  margin: 0 auto;
}

.control-wrapper {
  height: 100%;
}

.control-section {
  margin-top: 100px;
}

.catelog_images_container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.catelog_images {
  max-height: 300px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}