.nav-tabs {
    border-bottom: 0px solid transparent;
}
.top_heading{
    margin-bottom: 41px;
}
.payment h4{
    color: #0C192C;
    font-size: 16.5px;
    font-weight: 600;
    margin-bottom: 24px;
}
.payment_methods{
    padding: 10px 0px;
    background: #F5F5F5;
    margin-bottom: 20px;
}
.payment_methods button{
    color: #0C192C;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    padding: 5px;
    width: 100%;
    border-radius: 4px;
    border: none;   
    outline: none;
}
.payment_methods button.active{
    color: #FFF;
    background: #B4B4B4;
}
.payment_tabs{
    border-radius: 4.8px;
    border: 1px solid #E9EBEC;
    background: #FFF;
    
}
.payment_tabs .row{
    margin-top: 15px;
}
.payment_tabs .row1 span{
    color: #878A99;
    font-size: 13px;
    font-weight: 500;
    padding: 0px 4px;
    margin-bottom: 10px;
    display: block;
}

.payment_tabs .row1 input{
    border-radius: 4px;
    border: 1px solid #E9EBEC;
    background: #FFF;
    outline: none;
    padding: 11px 14px;
    width: 100%;
}
.payment_tabs .row1 input::placeholder{
    color: #D4D4D4;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.radio_btns{
    margin-bottom: 23px;
}
.radio_btns .form-check{
    display: inline-block;
    margin-right: 26px;

    
}
.radio_btns .form-check label{
    color: #878A99;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
}
.login_paypal_btn{
    text-align: end;
    margin-bottom: 20px;

}
.login_paypal_btn button{
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    border: none;
    outline: none;
    border-radius: 9999px;
    background: #FCFB00;
    box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
    padding: 12px 47px;
    cursor: pointer;
}

.login_paypal_btn button:disabled{
    cursor: not-allowed !important;
    background: #f2f2aa;
}

.promo_code {
    display: flex;
    align-items: center; /* Vertically center the input and button */
    justify-content: space-between; /* Separate the input and button */
    max-width: 400px; /* You can adjust this value to limit the maximum width of the container */
    margin: 0 auto; /* Center the container horizontally */

}

/* Style for the input field */
.promo_code input[type="text"] {
    flex: 1; /* The input field will take up the available space */
    min-width: 0; /* To ensure the input can shrink */
    width: 100%; /* Make sure the input takes full width initially */
}

/* Optional style for the button */
.promo_code button {
    flex-shrink: 0; /* Prevent the button from shrinking */
    /* Add your button styles here */
}
.right_cards{
    margin-bottom: 25px;
}
.cart_card{
    border-radius: 4.8px;
    border: 1px solid #E9EBEC;
    background: #FFF;
    padding: 20px;
}

.cart_card h5{
    color: #0C192C;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}
.cart_card .promo_code input{
    border-radius: 4px;
    border: 1px solid #E9EBEC;
    background: #FFF;
    padding: 8px;
    color: #0C192C;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    margin-right: 16px;

}
.cart_card .promo_code button:disabled{
    border-radius: 4px;
    cursor: not-allowed;
    border: 1px solid #B4B4B4;
    background: #B4B4B4;
    padding: 9px 21px;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 500;
}

.cart_card .promo_code button{
    border-radius: 4px;
    border: 1px solid #FCFB00;
    background: #FCFB00;
    padding: 9px 21px;
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 500;
}

.order_summary{
    margin-bottom: 46px;
}
.summary{
    color: #0C192C;
    font-size: 13px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
}
.check_out{
    margin-top: 10px;
}
.check_out button{
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border-radius: 9999px;
    background: #FCFB00;
    box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
    border: none;
    outline: none;
    padding: 17px 68px;

    
}

.shipping_charge_heading{
    text-align: center;
    margin-top: 30px;
}


#shippingList{
    list-style-type: none;
    padding: 0px;
}
#shippingList li span{
    font-weight: 600;
}
#shippingList ul li::marker{
    color: #d1d000;
}
#shippingList hr{
    color: #E9EBEC;
    opacity: 100%;
    margin: 15px 0px;
}
.shipping_container{
    margin-top: 5px;
}
.shipping_card.active{
    background: #dfdfdf !important;
}
.shipping_card{
    border-radius: 4.8px;
    border: 1px solid #E9EBEC;
    background: #FFF;
    padding: 10px;
    cursor: pointer;
}
.shipping_card .name{
    text-align: center;
    color: #0C192C;
    font-size: 16px;
    font-weight: 600;
    background: #FCFB00;
    border-radius: 10px;
    padding: 10px;
}



@media (max-width: 320px) {
    .promo_code {
        flex-direction: column;
    }
    .cart_card.promo_code input {
        margin-right: 0px;
        margin-bottom: 10px;
    }

}

@media (max-width: 576px){
    .payment_tabs{
        border: 0px solid transparent;
    }
    .login_paypal_btn{
        text-align: center;
    }
}

@media (max-width: 991px){
    .payment_methods button{
        font-size: 11px;
    }
    .payment_tabs{
        margin-bottom: 25px;
    }
}
@media (min-width: 992px){
    
    .right-side {
        width: 63.333333% !important;
    }
}


.cursor-not-allowed{
    cursor: not-allowed;
}

