.stripe-form {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10% 0;
}

.form-row {
  margin-bottom: 15px;
  padding: 2% 0;
}

#card-element {
  border: 1px solid #ccc;
  padding: 3% !important;
}

.form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.card-errors {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.submit-btn {
  width: 100%;
  background-color: #fdfa08;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #fdfa08;
}

.submit-btn:disabled {
  background: #acac009c;
  cursor: not-allowed;
}

.cancel-btn {
  width: 100%;
  background-color: #bcbaba;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #a3a39f;
}
