.navbar-home {
  background: #f6f6f6 !important;
}

.bottom_nav-home {
  background-color: #fff !important;
}
@media (max-width: 991px) {
  .navbar-home {
    background: #fff !important;
  }
}

/* Hero Section */

.hero_section {
  background: rgba(204, 204, 204, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero_container {
  height: 90vh;
  display: flex;
}
.hero_section h1 {
  font-size: 72px;
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 12px;
}
.hero_section h1 span {
  font-family: Rage;
  font-size: 90px;
  font-weight: 400;
  letter-spacing: -1px;
}
.hero_section p {
  color: #555;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 26px;
}
.hero_section button {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border-radius: 9999px;
  background: #fcfb00;
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: 27px 30px;
  border: none;
  outline: none;
}
/* Hero Section */

/* Section 2 */

.section_2 {
  padding-top: 86px;
  margin-bottom: 130px;
}
.section_2 .box {
  position: relative;
  background: #fbfbfb !important;
  /* box-shadow: -11px 11px 2px -3px #B4B4B4; */
  box-shadow: 0px 3.0136301517486572px 3.0136301517486572px 0px
    rgba(0, 0, 0, 0.1);
  border-radius: 0px 75.341px;
  margin-top: 55px;
}
.shadow_top {
  z-index: 10;
}
.shadow {
  background: #b4b4b4;
  border-radius: 0px 77px;
  position: absolute;
  top: 17px;
  left: -6px;
  right: 13px;
  bottom: -8px;
  z-index: -1;
  filter: blur(1px);
}

.section_2 .box img {
  position: absolute;
  top: -18px;
  left: 30px;
}

.box .box_content {
  margin: 36px 0px;
  margin-left: 147px;
  margin-left: 147px;
  padding-right: 15px;
}
.box .box_content h4 {
  color: #000;
  font-size: 16.413px;
  font-weight: 600;
}
.box .box_content p {
  color: #555;
  font-size: 10.942px;
  font-weight: 400;
}

/* Section 2 */

/* Item Selection */

.card {
  padding: 10px;
}
.view_more_btn a {
  color: #f2f2f2;
}
.view_more_btn {
  border-radius: 9999px;
  background: #000;
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  text-align: center;
  font-size: 16px;
  font-weight: 400;
  width: auto;
  padding: 15px 60px;
  margin: auto;
  border: none;
  outline: none;
}
.p_colors {
  cursor: default;
}
.p_colors .color_border {
  width: fit-content;
  margin: 0px;
  display: inline-flex;
  border-radius: 50%;
}
.color_border.active {
  border: 1px solid transparent;
}
.p_colors .color {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin: 3px;
  border: none;
  outline: none;
  cursor: pointer;
}

/* Item Selection */

/* Email Section */

.email_section {
  background: linear-gradient(203deg, #edec41 0%, #fcfb00 100%);
}

.email_row {
  padding: 115px 0px;
}

.sale_text {
  color: #0c192c;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.sale_text span {
  color: #ef476f;
  font-weight: 600;
}

.email_content h3 {
  color: #0c192c;
  font-size: 33px;
  font-weight: 600;
  text-transform: capitalize;
}
.email_content p {
  color: #0c192c;
  font-size: 14px;
  font-weight: 400;
}
.email_input {
  position: relative;
}
.email_input input {
  border-radius: 800px;
  border: 1px solid #e9ebec;
  background: #fff;
  padding: 13px 24px;
  width: 100%;
  outline: none;
}
.email_input input::placeholder {
  color: #0c192c;
  font-size: 13px;
  font-weight: 400;
}

.email_input button {
  position: absolute;
  top: 5px;
  right: 6px;
  border-radius: 800px;
  border: 1px solid #fcfb00;
  background: #fcfb00;
  outline: none;
  color: #000;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding: 11px 16px;
}

/* Email Section */

/* FAQ Section */
.faq_section {
  margin-bottom: 242px;
}
.faq_section h2 {
  margin-bottom: 75px;
}
.about_question_sec {
  padding: 30px;
  border-radius: 5.702px;
  border: 1.14px solid #dae2ef;
  background: #fff;
}
.aqs_content {
  padding: 10px;
  width: max-content;
}
.aqs_title_con {
  margin: 7.5px 0px;
}
.aqs_title_con button {
  border-radius: 5.702px;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  text-align: initial;
}
.aqs_title_con .active {
  background: #fcfb00;
}
.aqs_image {
  width: 35px;
}
.aqs_image,
.aqs_title {
  display: inline-flex;
  vertical-align: bottom;
  justify-content: center;
}
.aqs_title {
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
}

.ques_answer_sec .accordion-item {
  border-radius: 5.702px;
  border: 1.14px solid #eef1f6;
  background: #fff;
  margin-bottom: 19px;
}
.ques_answer_sec .accordion-header button {
  color: #0e1133;
  font-size: 18px;
  font-weight: 500;
  padding: 24px 20px;
}

.phone_accordian_btns {
  text-align: center;
  margin-bottom: 33px;
  display: none;
  width: fit-content;
  display: inline-block;
  background: none;
  border: none;
  margin-left: 14px;
  margin-right: 14px;
  outline: none;
  background-color: transparent;
}
.phone_accordian_btns img {
  display: block;
  margin: auto;
}
.phone_accordian_btns span {
  display: block;
  color: #0c192c;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  margin-top: 7px;
}
.nav_for_phone {
  justify-content: center;
  display: none;
}

/* FAQ Section */

@media (max-width: 350px) {
  .hero_section h1 {
    font-size: 30px !important;
  }
  .hero_section h1 span {
    font-size: 45px !important;
  }
  .hero_container {
    padding-top: 40px !important;
    padding-bottom: 20px !important;
  }
}

@media (max-width: 500px) {
  .section_2 .box img {
    width: 80px;
    top: -11px;
    left: 20px;
  }
  .box .box_content {
    margin: 20px 0px;
    margin-left: 84px;
  }
  .box .box_content h4 {
    font-size: 13px;
  }
}
@media (max-width: 576px) {
  .hero_section h1 {
    color: #000;
    text-align: center;
    font-size: 50px;
    font-weight: 600;
  }
  .hero_section h1 span {
    color: #000;
    font-size: 65px;
    font-weight: 400;
  }
  .hero_section p {
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    width: 85%;
    margin: auto;
    margin-bottom: 35px;
  }
  .section_2 {
    padding-top: 70px;
  }

  .section_2 .box {
    margin-top: 40px;
  }

  .email_section {
    display: none;
  }
  .bottom_links {
    margin-bottom: 15px;
  }
  .bottom_logo {
    margin-bottom: 15px !important;
  }
}

@media (max-width: 767px) {
  .hero_section {
    display: block;
    height: 100%;
  }
  .hero_container {
    height: 100% !important;
    display: block;
    padding-top: 60px;
    padding-bottom: 53px;
  }
  .hero_container .row {
    display: block;
  }

  .hero_section .started_btn {
    text-align: center;
  }
  .hero_section .started_btn button {
    padding: 14px 15px;
    font-size: 13px;
  }

  .email_row {
    padding: 30px 0px;
  }
  .about_question_sec {
    display: none;
  }
  .nav_for_phone {
    display: block;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .about_question_sec {
    padding: 15px;
  }
  .aqs_title {
    font-size: 12px;
  }
  .aqs_image img {
    height: 17px;
  }
  .email_section {
    margin-bottom: 45px !important;
  }
  .faq_section h2 {
    margin-bottom: 45px;
  }
}
