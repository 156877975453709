.p_detail_bar a {
  color: #1565c0;
  font-size: 14px;
  font-weight: 400;
  margin-right: 23px;
}
.p_detail_bar span {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  font-weight: 400;
}
.gai_content {
  margin-top: 150px;
}
.gai_content h1 {
  color: #000;
  text-align: center;
  font-size: 58.81px;
  font-weight: 600;
  letter-spacing: -0.817px;
}
.gai_content .rage_text {
  color: #000;
  font-family: Rage !important;
  font-size: 73.512px;
  font-weight: 400;
  letter-spacing: -0.817px;
}
.gai_input {
  position: relative;
  margin-top: 57px;
}
.gai_input i {
  position: absolute;
  top: 9px;
  left: 16px;
  font-size: 22px;
}

.gai_input input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #b4b4b4;
  outline: none;
  padding: 11px 52px;
}
.gai_input input::placeholder {
  color: #000;
  font-size: 15px;
  font-weight: 300;
}
.gai_content .row {
  margin-top: 14px;
  margin-bottom: 14px;
}
.gai_content .limit {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 300;
}
.gai_content .working {
  color: #fb1d1d;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.gai_buttons {
  border-radius: 9999px;
  background: #fcfb00;
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border: none;
  outline: none;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  padding: 17px 70px;
  margin-bottom: 65px;
}
.gai_buttons:disabled {
  background: #acac009c;
  cursor: not-allowed;
}

.ai_images {
  margin-bottom: 64px;
}
.ai_images img {
  margin-bottom: 20px;
}
.start_designing_btn {
  text-align: center;
}
.start_designing_btn button {
  margin-top: 10px;
  padding: 17px 50px;
}

.how_work_popup .modal-header {
  border-bottom: 1px solid transparent !important;
  position: relative;
}
.how_work_popup .modal-header svg {
  position: absolute;
  top: 12px;
  right: 15px;
}

.how_work_popup .modal-body p {
  color: #616161;
  font-size: 16px;
  font-weight: 300;
}
.how_work_popup .modal-body h3 {
  margin-bottom: 30px;
}
/* For Color Balls */

.p_colors {
  cursor: default;
}
.p_colors .color_border {
  width: fit-content;
  margin: 0px;
  display: inline-flex;
  border-radius: 50%;
  /* border: 1px solid !important; */
}
.color_border.active {
  border: 1px solid transparent;
}

.p_colors .color {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin: 3px;
  cursor: pointer;
}

/* For Color Balls */

/* For new text */

.image_side {
  position: relative;
}

.text_added {
  position: absolute;
  left: 0px;
  top: 0px;
  user-select: none;
}
#main_image {
  width: 600px;
  height: 550px;
}
.canvas-container {
  margin: auto !important;
}
.save_image_btn button {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 9999px;
  background: #fcfb00;
  padding: 18px 43px;
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-bottom: 23px;
}
.save_image_btn p {
  font-size: 15px;
  opacity: 80%;
}
.save_image_btn p span {
  color: red;
  font-size: 15px;
  font-weight: 700;
}

/* For new text */

.f-button svg {
  stroke: white !important;
}

@media (min-width: 576px) {
  .how_work_popup .modal-dialog {
    max-width: 771px !important;
    margin: 1.75rem auto;
  }
  .how_work_popup .modal-body {
    padding: 35px 80px !important;
  }
}

@media (max-width: 576px) {
  .how_work_popup .modal-body p {
    font-size: 12px !important;
  }
  .how_work_popup .modal-body {
    padding: 30px !important;
  }
}

@media (max-width: 767px) {
  .gai_content {
    margin-top: 110px;
  }
  .gai_content h1 {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -0.422px;
  }
  .gai_content .rage_text {
    font-size: 37.951px;
    font-weight: 400;
    letter-spacing: -0.422px;
  }
  .gai_buttons {
    font-size: 13px;
    font-weight: 500;
    padding: 12px 30px;
  }
  .gai_input {
    margin-top: 33px;
  }
}

.catelog_images_container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.catelog_images {
  max-height: 300px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
