.p_colors{
    cursor: default;
}
.p_colors .color_border{
    width: fit-content;
    margin: 0px;
    display: inline-flex;
    border-radius: 50%;
}
.color_border.active{
    border: 1px solid transparent;
}
.p_colors .color{
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    color: transparent;
}
.card{
    padding: 10px;  
}