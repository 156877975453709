td{
    vertical-align: middle;
}
th{
    width: 20%;
    vertical-align: middle;

}

.main_container {
    margin-top: 77px;
    margin-bottom: 77px;
}
.order{
    border: 1px solid #ffffff;
    padding: 20px;
    border-radius: 6px;
    background-color: #f2f2f2f2;
    margin-bottom: 40px;
}