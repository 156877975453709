.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 450px;
    max-width: 90%;
  }
  
  .popup-header {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-icon {
    cursor: pointer;
    color: #999;
  }
  
  .popup-content {
    margin-top: 10px;
  }
  