.super_main .FAQs_container{
    margin-top: 126px;
    margin-bottom: 126px;
}
.nav-tabs {
    border-bottom: 0px solid transparent !important;
}
.question_heading {
    margin-bottom: 70px;
}
.question_heading h1{
    color: #0C192C;
    text-align: center;
    font-size: 22.5px;
    font-weight: 600;
}
.question_heading p{
    color: #878A99;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
}
.qc_row{
    margin-bottom: 44px;
}
.about_question .content{
    border-radius: 4.8px;
    border: 1px solid #E9EBEC;
    background: #FFF;
    text-align: center;
    cursor: pointer;
}
/* .about_question .content img{
    display: block;
    margin: auto;
} */
.about_question .content img{
    margin-top: 19px;
}
.about_question .content span{
    color: #0C192C;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}
    

.about_question .content{
    padding-bottom: 35px;
}
.general_heading h1{
    color: #0C192C;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 24px;
}

/* accordian */

.accordian_row .accordion-item{
    margin-bottom: 10px;
}
.accordion-button:focus {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}
.accordian_row .accordion-item{
    /* border-top: inherit !important; */
    border-radius: 4px;
    border: 1px solid #E9EBEC !important;
}

.accordian_row .accordion-button:not(.collapsed){
    border-radius: 4px 4px 0px 0px;
    background: #D4D4D4;
    box-shadow: 0px -1px 0px 0px #E9EBEC inset;    
}
.accordian_row .accordion-button{
    color: #000 !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    
}
.accordian_row .accordion-button::after{
    display: none;
}
.accordian_row .accordion-body{
    color: #878A99;
    font-size: 15px;
    font-weight: 400;
}
.accordian_row .accordion-body span{
    font-weight: 600;
}

/* accordian */


@media (max-width: 767px){
    .FAQs_container{
        margin-top: 0px !important;
        margin-bottom: 100px !important;
    }
    .question_heading{
        display: none;
    }
    .about_question .content{
        border: none;
    }
    .about_question .content img{
        max-width: 70px;
    }
    .about_question .content span{
        font-size: 12px;
    }

}
